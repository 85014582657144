import React, { Fragment, useContext } from "react";
import { observer } from "mobx-react-lite";
import { StoreContext, storeInterface, store } from "../store";
import { useQuery } from "react-apollo-hooks";
import { Link as RouterLink } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Typography, Paper, Button } from "@material-ui/core";
import { isEmpty } from "underscore";
import {
  getAllActivities,
  ActivityInterface,
  lessonInterface,
  getAllACtivitiesInterface,
  quizInterface,
  GET_GRADES,
} from "../queriesAndMutations";
import axios from "axios";
import CheckRecovery from "./CheckRecovery";
import "../css/ListPendingActivities.css";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    inline: {
      display: "inline",
    },
    paper: {
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 15,
      paddingRight: 15,
      marginLeft: 15,
      marginRight: 15,
    },
  });

interface ClassesInterface {
  root: string;
  inline: string;
  paper: string;
}

interface ActivityProps {
  activity: ActivityInterface;
  lesson: lessonInterface;
  classes: ClassesInterface;
}
interface QuizRelationProps {
  relation: quizInterface;
}
const Activity = ({ activity, lesson }: ActivityProps) => {
  const { type } = activity;
  // Only to satisfy typescript (see https://material-ui.com/guides/typescript/)
  const MyLink = (props: any) => (
    <RouterLink
      style={{ color: "black" }}
      to={{ pathname: "/viewActivity", state: { activity, lesson } }}
      {...props}
    />
  );

  return (
    <Fragment>
      <ListItem component={MyLink} alignItems="flex-start">
        <Typography variant="caption">
          {type} - {activity.title}
        </Typography>
      </ListItem>
    </Fragment>
  );
};
export const ColorAttention = (date: string) => {
  let currentColor = "default";
  let currentDate = new Date().getTime();
  let datePros = new Date(date).getTime();
  let week4 = 2419200000;
  let week6 = 3628800000;
  let week8 = 4838400000;
  if (currentDate - datePros >= week4 && currentDate - datePros < week6) {
    currentColor = "week4";
  }
  if (currentDate - datePros >= week6 && currentDate - datePros < week8) {
    currentColor = "week6";
  }
  if (currentDate - datePros >= week8) {
    currentColor = "week8";
  }
  return currentColor;
};

const Quiz = ({ relation }: QuizRelationProps) => {
  const MyLink = (props: any) => (
    <RouterLink
      style={{ color: "black" }}
      to={{ pathname: "/quiz/" + relation.quizByQuiz.id }}
      {...props}
    />
  );

  return (
    <Fragment>
      <ListItem component={MyLink} alignItems="flex-start">
        <Typography variant="caption">{relation.quizByQuiz.name}</Typography>
      </ListItem>
    </Fragment>
  );
};

interface ListActivitiesProps {
  classes?: ClassesInterface;
}

const ListActivities = observer(({ classes }: ListActivitiesProps) => {
  const store = useContext(StoreContext);
  const email = store.email;
  const turmaId = store.ui.turma.id;
  const { data: grades } = useQuery(GET_GRADES, {
    variables: { turma: store.ui.turma.id, email: store.email },
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });
  //store.ui.painel.pendentes = false;
  const { data, error, loading, refetch } = useQuery<getAllACtivitiesInterface>(
    getAllActivities,
    {
      variables: { email, turmaId },
      fetchPolicy: "cache-first",
      notifyOnNetworkStatusChange: true,
    }
  );
  // see https://github.com/apollographql/apollo-client/issues/3660
  if (loading && isEmpty(data)) return <p>Carregando... </p>;
  if (error) return <p>ERROR: {error.message}</p>;
  const lesson = data!.lesson;
  const quiz_relation = data!.quiz_relation;
  store.ui.refetch = refetch;

  const quizPairs = {
    1177: 1162,
    1162: 1177,
    1163: 1178,
    1178: 1163,
  };

  const isPendingQuiz = (r: any) => {
    if (r.quizByQuiz.id in quizPairs) {
      const otherQuizId = quizPairs[r.quizByQuiz.id as keyof typeof quizPairs];
      const otherQuiz = quiz_relation.find(
        (q) => q.quizByQuiz.id === otherQuizId
      );
      return (
        !r.quizByQuiz.quiz_attempts[0] &&
        (!otherQuiz || !otherQuiz.quizByQuiz.quiz_attempts[0])
      );
    }
    return (
      !r.quizByQuiz.quiz_attempts[0] &&
      (!r.quizByQuiz.name.toUpperCase().includes("RECUPERAÇÃO") ||
        CheckRecovery(quiz_relation, grades))
    );
  };

  const pendingQuizzes = quiz_relation.filter((r) => {
    if (store.ui.turma.name.includes("RFT")) {
      return !r.quizByQuiz.quiz_attempts[0];
    }
    return isPendingQuiz(r);
  });
  const validTurmaIds: number[] = [];
  var re_enroll = validTurmaIds.includes(turmaId);
  return (
    <>
      {re_enroll && (
        <div style={{ marginBottom: "20px", display: "hidden" }}>
          <Paper className={classes!.paper} elevation={2}>
            <Typography variant="h5">
              Rematrícula para o próximo período liberada.
              <br />
              Clique no botão para registrar e acesse a próxima turma no menu à
              esquerda:
            </Typography>
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: "10px" }}
              onClick={async () => {
                try {
                  let { data } = await axios.post(
                    "/.netlify/functions/re_enroll",
                    {
                      email,
                      course: store.ui.turma.course,
                      turma: store.ui.turma.name,
                    }
                  );
                  alert(JSON.stringify(data));
                  window.location.reload();
                } catch (e) {
                  alert((e as Error).message);
                }
              }}
            >
              Rematrícula
            </Button>
          </Paper>
        </div>
      )}
      {store.ui.turma.course.includes("ACT") ||
      store.ui.turma.course.includes("RFT") ||
      store.ui.turma.course.includes("FAP") ? (
        <Paper
          className={classes!.paper}
          elevation={2}
          style={{ marginBottom: 20 }}
        >
          <Typography variant="h6" style={{ color: "#801819" }}>
            Cores de alerta:{" "}
          </Typography>
          <div className="captionColors">
            {" "}
            <div className="yellowColor" /> Atividade ou avaliação pendente há
            mais de 4 semanas{" "}
          </div>
          <div className="captionColors">
            {" "}
            <div className="orangeColor" /> Atividade ou avaliação pendente há
            mais de 6 semanas{" "}
          </div>
          <div className="captionColors">
            {" "}
            <div className="redColor" /> Atividade ou avaliação pendente há mais
            de 8 semanas{" "}
          </div>
        </Paper>
      ) : (
        ""
      )}
      {!store.admin && (
        <div
          style={{
            display: pendingQuizzes.length > 0 ? "block" : "none",
            marginBottom: "20px",
          }}
        >
          <Paper className={classes!.paper} elevation={2}>
            <Typography variant="h6" style={{ color: "#801819" }}>
              Avaliações Pendentes:
            </Typography>
            <List className={classes!.root}>
              <div
                className={
                  store.ui.turma.org === "EMF" ? "provasEMF" : ""
                }
              >
                {pendingQuizzes.map((r) => (
                  <div
                    className={
                      store.ui.turma.course.includes("ACT") ||
                      store.ui.turma.course.includes("FAP") ||
                      store.ui.turma.course.includes("RFT")
                        ? ColorAttention(r.quizByQuiz.created_at)
                        : "default"
                    }
                  >
                    <Quiz relation={r} />
                  </div>
                ))}
              </div>
            </List>
          </Paper>
        </div>
      )}
      {store.admin && (
        <div
          style={{
            display: quiz_relation.length > 0 ? "block" : "none",
            marginBottom: "20px",
          }}
        >
          <Paper className={classes!.paper} elevation={2}>
            <Typography variant="h6" style={{ color: "#801819" }}>
              Avaliações:
            </Typography>
            <List className={classes!.root}>
              {quiz_relation
                .filter(
                  (r) =>
                    !(r.quizByQuiz.id in quizPairs) ||
                    r.quizByQuiz.id >
                      quizPairs[r.quizByQuiz.id as keyof typeof quizPairs]
                )
                .map((r) => {
                  return (
                    <div
                      className={
                        store.ui.turma.course.includes("ACT") ||
                        store.ui.turma.course.includes("FAP")
                          ? ColorAttention(r.quizByQuiz.created_at)
                          : "default"
                      }
                    >
                      <Quiz relation={r} />
                    </div>
                  );
                })}
            </List>
          </Paper>
        </div>
      )}
      <div
        style={{
          display:
            store.ui.painel.pendentes && !store.ui.turma.name.includes("J1")
              ? "block"
              : "none",
        }}
      >
        {!store.ui.turma.name.includes("Rodas") &&
        !store.ui.turma.name.includes("Supervisão") ? (
          <Paper className={classes!.paper} elevation={2}>
            <Typography variant="h6" style={{ color: "#801819" }}>
              Relatório de Atividades Pendentes
            </Typography>
            <Typography variant="subtitle1" style={{ color: "#801819" }}>
              (Atividades já realizadas estão na página Atividades)
            </Typography>{" "}
            <br />
            {lesson.map((l) => (
              <ListHolder
                store={store}
                key={l.id}
                lesson={l}
                classes={classes}
              />
            ))}
          </Paper>
        ) : (
          <Paper className={classes!.paper} elevation={2}>
            <Typography variant="h5">
              Vídeos que você ainda não assistiu
            </Typography>
            <Typography variant="h6">
              (Vídeos que você já assistiu estão na página Vídeos)
            </Typography>{" "}
            <br />
            {lesson.map((l) => (
              <ListHolder
                store={store}
                key={l.id}
                lesson={l}
                classes={classes}
              />
            ))}
          </Paper>
        )}
      </div>
    </>
  );
});

interface ListHolderProps {
  store: storeInterface;
  lesson: lessonInterface;
  classes?: ClassesInterface;
}

const ListHolder = ({ lesson, classes }: ListHolderProps) => {
  const pendingActivities = lesson.activities.filter((act) =>
    !act.deleted_at && act.activity_completions[0]
      ? !act.activity_completions[0].completed
      : act.available && act.type !== "Avaliação"
      ? true
      : false
  );
  if (pendingActivities.length > 0) {
    store.ui.painel.pendentes = true;
  }
  return (
    <Fragment>
      {pendingActivities.length > 0 && (
        <Fragment>
          <br />
          {!store.ui.turma.course.includes("Rodas") &&
          !store.ui.turma.course.includes("Supervisão") ? (
            <Typography variant="body1">
              Lição {lesson.number.toString()}: {lesson.title} (
              {pendingActivities.length.toString()} pendentes)
            </Typography>
          ) : (
            <Typography variant="body1">
              Vídeo {lesson.number.toString()}: {lesson.title} (
              {pendingActivities.length.toString()} pendentes)
            </Typography>
          )}
          <br />
          <List className={classes!.root}>
            {pendingActivities.map((activity) => {
              return (
                <div
                  className={
                    store.ui.turma.course.includes("ACT") ||
                    store.ui.turma.course.includes("FAP")
                      ? ColorAttention(lesson.start_date)
                      : "default"
                  }
                >
                  <Activity
                    key={activity.id}
                    activity={activity}
                    lesson={lesson}
                    classes={classes!}
                  />
                </div>
              );
            })}
          </List>
        </Fragment>
      )}
    </Fragment>
  );
};

export default withStyles(styles, { withTheme: true })(ListActivities);

import React, { useContext } from "react";
import { useQuery } from "react-apollo-hooks";
import gql from "graphql-tag";
import { StoreContext } from "../store";
import { observer } from "mobx-react-lite";
import MUIDataTable from "mui-datatables";
import { Button } from "@material-ui/core";
import "../css/Grades.css";

const query = gql`
  query getAttempts($email: String, $turma: Int) {
    turma(where: { id: { _eq: $turma }, deleted_at: { _is_null: true } }) {
      id
      quiz_relations(
        where: { quizByQuiz: { _not: { disciplina_id: { _eq: 158 } } } }
      ) {
        quizByQuiz {
          id
          name
          questions {
            id
            pontos
          }
          quiz_attempts(
            where: {
              personByPerson: {
                email: { _eq: $email }
                enrollments: {
                  registers: {
                    turma: { _eq: $turma }
                    deleted_at: { _is_null: true }
                  }
                  deleted_at: { _is_null: true }
                }
                deleted_at: { _is_null: true }
              }
              deleted_at: { _is_null: true }
            }
          ) {
            id
            quiz
            created_at
            personByPerson {
              id
              first_name
              last_name
            }
            question_completions {
              id
              answer
              question_option {
                id
                correct
                questionByQuestion {
                  pontos
                  null_question
                }
              }
            }
          }
        }
      }
      registers {
        is_professor
        is_tutor
        is_team_member
        is_monitor
        is_audit
        is_evaluator
        suspended
        cancelled
        locked
        dependency
        enrollmentByEnrollment {
          person {
            first_name
            last_name
            email
          }
        }
      }
    }
    disciplina(
      where: {
        lessons: { turma: { _eq: $turma } }
        deleted_at: { _is_null: true }
      }
      order_by: { id: asc }
    ) {
      id
      name
      courseworks(
        where: {
          turma: { _eq: $turma }
          personByPerson: { email: { _eq: $email } }
          deleted_at: { _is_null: true }
        }
      ) {
        grade
        title
        personByPerson {
          id
          first_name
          last_name
        }
      }
    }
    retake2 {
      person
      quiz
      grade
    }
  }
`;

const GradesAntigo = observer((props) => {
  const store = useContext(StoreContext);
  var variables = { turma: store.ui.turma.id };
  const course = store.ui.turma.course;
  if (!(store.admin || store.is_monitor)) variables.email = props.email;
  var { data, error, loading } = useQuery(query, { variables: variables });
  if (loading) return <p>Carregando...</p>;
  if (error) return <p>Erro: {JSON.stringify(error)}</p>;
  if (data.turma.length === 0) return <p>Turma não encontrada.</p>;
  
  data.disciplina = data.disciplina.filter((d) => d.courseworks.length > 0);
  
  var grades = [];
  var quizids = [];
  var quiz_values = {};
  var titles = {};

  if (store.admin || store.is_monitor) {
    data.turma[0].registers.forEach((register) => {
      let name =
        register.enrollmentByEnrollment.person.first_name +
        " " +
        register.enrollmentByEnrollment.person.last_name;
      let title = [
        register.suspended && "SUSPENSO",
        register.cancelled && "CANCELADO",
        register.locked && "Matrícula trancada",
        register.dependency && "fazendo dependência",
        register.is_tutor && "Tutor",
        register.is_monitor && "Monitor",
        register.is_professor && "Professor",
        register.is_team_member && "Equipe",
        register.is_audit && "Ouvinte",
        register.is_evaluator && "Avaliador",
      ].filter(Boolean);
      titles[name] = title.length > 0 ? ` (${title.join(", ")})` : "";
    });
  }

  if (store.admin) {
    if (data.turma[0].quiz_relations) {
      data.turma[0].quiz_relations.forEach((relation) => {
        var quiz = relation.quizByQuiz;
        var id = quiz.name;
        var total = 0;
        quiz.questions.forEach((question) => {
          if (question.pontos) total += question.pontos;
        });
        total = Math.round(total * 100) / 100;
        quiz_values[id] = total;
        quizids.push({
          name: id,
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              var color = value && value.grade >= 0.7 * total ? "black" : "red";
              return (
                <>
                  <div style={{ color: color, fontWeight: "bold" }}>
                    {value ? `${value.grade} (${value.attempts}/3)` : ""}
                  </div>
                </>
              );
            },
            onCellClick: (_, cmeta) => {
              props.history.push("/quiz/" + quiz.id);
            },
          },
        });
        var retake2 = data.retake2.filter((r) => r.quiz === quiz.id);
        if (retake2.length > 0) {
          quizids.push({
            name: "2ª Recuperação",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                var color =
                  value && value.grade >= 0.7 * total ? "black" : "red";
                return (
                  <>
                    <div style={{ color: color, fontWeight: "bold" }}>
                      {value ? `${value.grade}` : ""}
                    </div>
                  </>
                );
              },
              onCellClick: (_, cmeta) => {
                props.history.push("/rerecuperacao");
              },
            },
          });
          quiz_values["2ª Recuperação"] = 10;
        }
        quiz.quiz_attempts.forEach((at) => {
          var nota = 0;
          var aluno =
            at.personByPerson.first_name + " " + at.personByPerson.last_name;
          at.question_completions.forEach((answer) => {
            if (
              answer.question_option.correct ||
              answer.question_option.questionByQuestion.null_question
            ) {
              var valor = answer.question_option.questionByQuestion.pontos;
              if (valor) nota += valor;
            }
          });
          nota = Math.round(nota * 100) / 100;
          var pos = grades.length;
          grades.forEach((linha, i) => {
            if (linha.nome === aluno) {
              pos = i;
              if (linha[id] && linha[id].grade > nota) nota = linha[id].grade;
            }
          });
          if (pos === grades.length) grades[pos] = { nome: aluno };
          if (!grades[pos][id]) grades[pos][id] = { grade: nota, attempts: 0 };
          grades[pos][id].grade = nota;
          grades[pos][id].attempts++;
          let grade_retake_2 = null;
          retake2.forEach((r) => {
            if (r.person === at.personByPerson.id) grade_retake_2 = r.grade;
          });
          if (grade_retake_2)
            grades[pos]["2ª Recuperação"] = {
              grade: nota + grade_retake_2,
              attempts: 0,
            };
        });
      });
    }
    data.disciplina.forEach((discipline) => {
      discipline.courseworks[0] &&
        quizids.push({
          name: discipline.name,
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              var color = value && value.grade >= 7 ? "black" : "red";
              return (
                <>
                  <div style={{ color: color, fontWeight: "bold" }}>
                    {value ? `${value.grade}` : ""}
                  </div>
                </>
              );
            },
          },
        });
      discipline.courseworks.forEach((coursework) => {
        var aluno =
          coursework.personByPerson.first_name +
          " " +
          coursework.personByPerson.last_name;
        var pos = grades.findIndex((linha) => linha.nome === aluno);
        if (pos < 0)
          grades.push({
            nome: aluno,
            [discipline.name]: {
              grade: coursework.grade,
            },
          });
        else grades[pos][discipline.name] = { grade: coursework.grade };
      });
      if (discipline.courseworks[0]) quiz_values[discipline.name] = 10;
    });
    grades.forEach((student) => {
      let passed = true;
      let retake = false;
      quizids.forEach((quiz) => {
        if (!quiz.name.toUpperCase().includes("RECUPERAÇÃO")) {
          passed &&=
            student[quiz.name] &&
            student[quiz.name].grade >= quiz_values[quiz.name] * 0.7;
        } else {
          retake |=
            student[quiz.name] &&
            student[quiz.name].grade >= quiz_values[quiz.name] * 0.7;
        }
      });
      passed = passed || retake;
      student.Resultado = passed ? "Aprovado" : "Reprovado";
    });
    quizids = [
      {
        name: "nome",
        options: {
          customBodyRender: (value, tableMeta, updateValue) =>
            value + (titles[value] || ""),
        },
      },
    ].concat(quizids);
    store.admin &&
      quizids.push({
        name: "Resultado",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            var color = value === "Reprovado" ? "red" : "black";
            return (
              <>
                <div style={{ color: color, fontWeight: "bold" }}>{value}</div>
              </>
            );
          },
        },
      });
  } else {
    quizids = [
      "Questionário",
      {
        name: "Nota",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            var color =
              parseInt(value.split("(")[1].split("%")[0]) >= 70
                ? "black"
                : "red";
            return (
              <>
                <div style={{ color: color, fontWeight: "bold" }}>{value}</div>
              </>
            );
          },
        },
      },
      "Tentativas usadas",
      {
        name: "quizId",
        label: "Refazer",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            if (tableMeta.rowData[0] === "2ª Recuperação" || !value) return "";
            return (
              <>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    props.history.push("/quiz/" + value);
                  }}
                >
                  Fazer Prova
                </Button>
              </>
            );
          },
        },
      },
    ];
    if (config[course] && config[course].quizanalysis)
      quizids.push({
        name: "attempt",
        label: "Correção",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            if (!value) return "";
            return (
              <>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    props.history.push("/quizanalysis", { attempt: value });
                  }}
                >
                  Ver Correção
                </Button>
              </>
            );
          },
        },
      });
    if (data.turma[0].quiz_relations) {
      data.turma[0].quiz_relations.forEach((relation) => {
        var quiz = relation.quizByQuiz;
        var id = quiz.name;
        var total = 0;
        var retake2 = data.retake2.filter(
          (r) => r.quiz === quiz.id && r.person === store.personId
        );
        quiz.questions.forEach((question) => {
          if (question.pontos) total += question.pontos;
        });
        total = Math.round(total * 100) / 100;
        var linha = { Questionário: id };
        let tentativas = 0;
        quiz.quiz_attempts.forEach((at) => {
          if (at.personByPerson.id !== store.personId) return;
          tentativas++;
          var nota = 0;
          at.question_completions.forEach((answer) => {
            if (
              answer.question_option.correct ||
              answer.question_option.questionByQuestion.null_question
            ) {
              var valor = answer.question_option.questionByQuestion.pontos;
              if (valor) nota += valor;
            }
          });
          nota = Math.round(nota * 100) / 100;
          if (!linha.Nota || linha.Nota < nota) linha.Nota = nota;
        });
        if (!linha.Nota) {
          linha.Nota = 0;
        }
        var nota = linha.Nota;
        linha.Nota +=
          "/" + total + " (" + Math.round((nota * 100) / total) + "%)";
        linha["Tentativas usadas"] = tentativas + "/3";
        linha.quizId = quiz.id;
        let lastAttempt = null;
        if (quiz.quiz_attempts[0]) {
          const sortedAttempts = quiz.quiz_attempts.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
          lastAttempt = sortedAttempts[0];
        }
        linha.attempt = lastAttempt;
        if (tentativas > 0) {
          grades.push(linha);
        }
        if (retake2.length > 0) {
          let finalGrade = nota + retake2[0].grade;
          grades.push({
            Questionário: "2ª Recuperação",
            Nota: `${finalGrade}/10 (${Math.round(finalGrade * 10)}%)`,
          });
        }
      });
    }
    data.disciplina.forEach((discipline) => {
      discipline.courseworks[0] &&
        grades.push({
          Questionário: discipline.name,
          Nota: `${discipline.courseworks[0].grade} (${Math.round(
            discipline.courseworks[0].grade * 10
          )}%)`,
        });
    });
  }

  var tableOptions = {
    responsive: "scroll",
    rowsPerPageOptions: [10, 50, 200],
    rowsPerPage: 200,
    selectableRows: false,
    filterType: "multiselect",
    sortOrder: {
      name: "nome",
      direction: "asc",
    },
  };

  return (
    <>
      <div style={{ display: "table", tableLayout: "fixed", width: "100%" }}>
        <MUIDataTable
          data={grades}
          columns={quizids}
          title={"Notas"}
          options={{
            ...tableOptions,
          }}
        />
      </div>
    </>
  );
});

export default GradesAntigo;

const config = {
  "Especialização FAP": {
    ignore: [
      "Aula 2 - parte 1",
      "Aula 2 - parte 2",
      "Não obrigatória",
      "Extra",
    ],
    quizanalysis: true,
  },
  "Formação FAP": {
    ignore: ["Aula 2 - parte 1", "Aula 2 - parte 2"],
    quizanalysis: true,
  },
  "Especialização ACT": {
    classes: ["Produzir Conteúdo"],
    quizanalysis: true,
  },
  "Formação ACT": {
    classes: ["Produzir Conteúdo"],
    quizanalysis: true,
  },
  "Curso Completo RFT": {
    quizanalysis: true,
  },
  "Módulo 1 RFT": {
    quizanalysis: true,
  },
};
import React from "react";
import "./App.css";
import history from "./history";
import RelinkDrawer from "./components/RelinkDrawer";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import Painel from "./components/Painel";
//import Auth from "./Auth/Auth";
import Alunos from "./components/Alunos";
import Login from "./components/Login";
import DBUI from "./components/DBUI";
import editDB from "./components/ChangeDBData";
import { store } from "./store";
import EditBasicData from "./components/EditBasicData";
import EditBasicDataAdmin from "./components/EditBasicDataAdmin";
import ListActivities from "./components/ListActivities";
import ViewActivity from "./components/ViewActivity";
import { observer } from "mobx-react-lite";
import Materials from "./components/Materials.jsx";
import { logAction, LogType } from "./log";
import Quiz from "./components/Quiz";
import Payments from "./components/payments/Payments";
import NewInvoice from "./components/payments/NewInvoice";
import Aux from "./components/Aux_teste";
import PaymentReport from "./components/payments/PaymentReport";
import Survey from "./components/Survey";
import AddAttendances from "./components/AddAttendances";
import ShowQuizAnalysis from "./components/ShowQuizAnalysis";
import Coursework from "./components/Coursework";
import Apply from "./components/Apply";
import ApplicationList from "./components/application/ApplicationList";
import Alunos_Monitores from "./components/Alunos_monitores";
import ApplyN2 from "./components/Apply-N2";
import HuggyControls from "./components/application/HuggyControls";
import HuggyAnalysis from "./components/application/HuggyAnalysis";
import CheckoutIugu from "./components/application/Checkout_iugu";
import CheckoutAsaas from "./components/application/Checkout_asaas";
import EMF_MedInd1 from "./components/application/EMF-MedInd1";
import Reretake from "./components/Reretake";
import ApplyN3 from "./components/Apply-N3";
import { checkVersion, storeVersion } from "./components/UpdateWarning";
import IMF from "./components/application/IMF";
import EMF_PA from "./components/application/EMF-Projeto";
import EditActivity from "./components/EditActivity";
import { StudentSchedule } from "./components/StudentSchedule";

import WS22 from "./components/application/workshops/Ceconte-Workshop22";



import GradesAdmin from "./components/Grades-admin";
import GradesStudent from "./components/Grades-student";
import Attendaces from "./components/Attendances";
import GradesAntigo from "./components/Grades-antigo";
import { AttachmentsPage } from "./components/AttachmentsStudentPage";
import { AttachmentsAdminPage } from "./components/AttachmentsAdminPage";
import FundamentosFap from "./components/application/CursoFundamentos/index"


store.email = localStorage.getItem("auth0:id_token:email") || undefined;
store.default_role =
  localStorage.getItem("auth0:id_token:default_role") || undefined;
store.auth_expires =
  Number(localStorage.getItem("auth0:expires_at")) || undefined;
store.is_monitor =
  JSON.parse(localStorage.getItem("isMonitor") || "false") || false;

const checkUser = () => store.authorized;
const checkAdmin = () =>
  store.authorized &&
  (store.default_role === "admin" || store.default_role === "manager");
const checkMonitor = () => store.authorized && (store.is_monitor || false);
const checkAdminOrMonitor = () => checkAdmin() || checkMonitor();
const checkEvaluator = () => store.authorized && store.is_evaluator;

const isEMFCourse = (course) => {
  const emfCourses = ["EMF", "MF", "N1", "N2", "N3"];
  return emfCourses.some(prefix => course.includes(prefix));
}

const App = observer(() => {
  if (isEMFCourse(store.ui.turma.course)) {
    document.getElementById("favicon").href = "/favicon2020.ico";
    document.title = "Painel do Aluno - Escola de Mindfulness Funcional";
  } else {
    document.getElementById("favicon").href = "/favicon-ceconte.ico";
    document.title = "Painel do Aluno - Ceconte";
  }
  if (store.authorized) storeVersion(store);
  return (
    <div>
      <Router history={history}>
        <RelinkDrawer>
          <Switch>
            <Route path="/" exact component={Painel} />
            <PrivateRoute
              authCheck={checkAdminOrMonitor}
              path="/alunos"
              component={Alunos}
            />
            <PrivateRoute
              authCheck={checkUser}
              path="/materials"
              component={Materials}
            />
            <PrivateRoute
              authCheck={checkUser}
              path="/matricula"
              component={EditBasicData}
            />
            <PrivateRoute
              authCheck={checkUser}
              path="/viewActivity"
              component={ViewActivity}
            />
            <PrivateRoute
              authCheck={checkUser}
              path="/listActivities"
              component={ListActivities}
            />
            <PrivateRoute
              authCheck={checkUser}
              path="/quiz/:id"
              component={Quiz}
            />
            <PrivateRoute
              authCheck={checkAdminOrMonitor}
              path="/edit/:email"
              component={EditBasicDataAdmin}
            />
            <PrivateRoute
              authCheck={checkAdmin}
              path="/edit/"
              component={EditBasicDataAdmin}
            />
            <PrivateRoute
              authCheck={checkAdmin}
              path="/viewDB/"
              component={DBUI}
            />
            <PrivateRoute
              authCheck={checkAdmin}
              path="/editDB/:table/:id?"
              component={editDB}
            />
            <PrivateRoute
              authCheck={checkUser}
              path="/payments"
              component={Payments}
            />
            <PrivateRoute
              authCheck={checkAdmin}
              path="/newinvoice"
              component={NewInvoice}
            />
            <PrivateRoute
              authCheck={checkAdmin}
              path="/report"
              component={PaymentReport}
            />
            <PrivateRoute authCheck={checkAdmin} path="/aux" component={Aux} />
            <PrivateRoute
              authCheck={checkUser}
              path="/survey"
              component={Survey}
            />
            <PrivateRoute
              authCheck={checkUser}
              path="/presenca"
              component={AddAttendances}
            />
            <PrivateRoute
              authCheck={checkUser}
              path="/quizanalysis"
              component={ShowQuizAnalysis}
            />
            <PrivateRoute
              authCheck={checkEvaluator}
              path="/coursework"
              component={Coursework}
            />
            <PrivateRoute
              authCheck={checkUser}
              path="/applicants"
              component={ApplicationList}
            />
            <PrivateRoute
              authCheck={checkAdmin}
              path="/monitores"
              component={Alunos_Monitores}
            />
            <PrivateRoute
              authCheck={checkUser}
              path="/n2"
              component={ApplyN2}
            />
            <PrivateRoute
              authCheck={checkUser}
              path="/n3"
              component={ApplyN3}
            />
            <PrivateRoute
              authCheck={checkAdmin}
              path="/huggy"
              component={HuggyControls}
            />
            <PrivateRoute
              authCheck={checkUser}
              path="/huggyanalysis"
              component={HuggyAnalysis}
            />
            <PrivateRoute
              authCheck={checkUser}
              path="/rerecuperacao"
              component={Reretake}
            />
            <PrivateRoute
              authCheck={checkAdmin}
              path="/editactivity/:id?"
              component={EditActivity}
            />
            <PrivateRoute
              authCheck={checkAdmin}
              path="/grades-admin"
              component={GradesAdmin}
            />
            <PrivateRoute
              authCheck={checkUser}
              path="/grades-student"
              component={GradesStudent}
            />
            <PrivateRoute
              authCheck={checkUser}
              path="/attendances"
              component={Attendaces}
            />
            <PrivateRoute
              authCheck={checkUser}
              path="/grades-antigo"
              component={GradesAntigo}
            />
            <PrivateRoute
              authCheck={checkUser}
              path="/attachmentsPageStudent"
              component={AttachmentsPage}
            />
            <PrivateRoute
              authCheck={checkAdmin}
              path="/attachmentsPageAdmin"
              component={AttachmentsAdminPage}
            />
            <PrivateRoute
              authCheck={checkUser}
              path="/student-schedule"
              component={StudentSchedule}
            />
            <Route path="/apply" component={Apply} />
            <Route path="/login" component={Login} />
            <Route path="/medint1" component={EMF_MedInd1} />
            <Route path="/imf" component={IMF} />
            <Route path="/projetoaplicado" component={EMF_PA} />
            <Route path="/checkout-iugu" component={CheckoutIugu} />
            <Route path="/checkout-asaas" component={CheckoutAsaas} />
            <Route path="/ws22" component={WS22} />
            <Route path="/fundamentos-fap" component={FundamentosFap} />
          </Switch>
        </RelinkDrawer>
      </Router>
    </div>
  );
});

const PrivateRoute = ({ authCheck, component: Component, path }) => {
  logAction(LogType.Route, path);
  checkVersion();
  //set last page viewed:
  if (window.location.pathname !== "/" && !store.logged_in) {
    let lastPage = window.location.href;
    localStorage.setItem("lastPage", lastPage);
  }

  return (
    <Route
      path={path}
      render={(props) =>
        authCheck() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default App;
